import React from 'react';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";

class Content extends React.PureComponent {
  componentDidMount() {
    let that = this;
    let flag = true;
    window.onscroll = function () {
      let dom = document.getElementsByClassName('swiper-c3');
      if (dom.length > 0) {
        if (flag) {
          that.loadSwiper();
        }
        flag = false;
      } else {
        flag = true;
      }
    }
  }

  //挂载swiper
  loadSwiper = () => {
    var swiper = new Swiper(".swiper-c3", {
      direction: getDirection(),
      speed: 500,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,//手动滑动不打断
      },
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      // mousewheel: true,
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
      on: {
        resize: function () {
          swiper.changeDirection(getDirection());
        }
      }
    });
    function getDirection() {
      var direction = window.innerWidth <= 760 ? "vertical" : "horizontal";
      return direction;
    }
  };

  render() {
    return (
      <div className='content3'>
        <OverPack className="home-layout" playScale={0.4}>
          <QueueAnim className="home-case" type="bottom" key="home-case" ease="easeOutQuart" leaveReverse>
            <div key="feasibiity" className='feasibiity'>当翼排与建筑行业相遇</div>
            <div key="solve" className='solve'>翼排产品面世前，我们组织过一场体验赛。共有六大设计院参与。下面是关于体验赛的细节</div>
            <QueueAnim
              key="content"
              type="bottom"
              component={Row}
              componentProps={{ gutter: 171 }}
              className='que-main'
            >
              <Col key='1' className="col" span={24}>
                <div className="swiper swiper-c3">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide slide-item">
                      <img src={require("../assets/images/slide1.jpg")} alt="loading..." />
                    </div>
                    <div className="swiper-slide slide-item">
                      <img src={require("../assets/images/slide2.png")} alt="loading..." />
                    </div>
                    <div className="swiper-slide slide-item">
                      <img src={require("../assets/images/slide3.png")} alt="loading..." />
                    </div>
                    <div className="swiper-slide slide-item">
                      <img src={require("../assets/images/slide4.png")} alt="loading..." />
                    </div>
                    <div className="swiper-slide slide-item">
                      <img src={require("../assets/images/slide5.png")} alt="loading..." />
                    </div>
                    <div className="swiper-slide slide-item">
                      <img src={require("../assets/images/slide6.png")} alt="loading..." />
                    </div>
                  </div>
                  <div className="swiper-button-prev swiper-btn swiper-prev"></div>
                  <div className="swiper-button-next swiper-btn swiper-next"></div>
                  {/* 分页器 */}
                  <div className="swiper-pagination"></div>
                </div>
              </Col>
            </QueueAnim>
          </QueueAnim>
        </OverPack>
      </div>
    );
  }
}

export default Content;
