import React, { Component } from 'react'

export class Footer extends Component {

    render() {
        return (
            <div className='footer'>
                <div className='way'>翼排，未来建筑设计师的工作方式</div>
                <div className='main'>
                    <div className='container'>
                        <div className='product'>
                            <div className='vessel'>
                                <img src={require("../assets/images/logo_w.svg")} alt="loading..." />
                                <span>翼排</span>
                            </div>
                        </div>
                        <div className='relation'>
                            <span className='title'>联系我们</span>
                            <div className='vessel'>
                                <img src={require("../assets/images/apply.png")} alt="loading..." />
                                <span onClick={() => this.props.openApplyModal()} style={{ cursor: 'pointer' }}>立即申请试用</span>
                            </div>
                            <div className='vessel'>
                                <img src={require("../assets/images/phone.png")} alt="loading..." />
                                <span>400-002-8299</span>
                            </div>
                            {/* <div className='vessel'>
                                <img src={require("../assets/images/phone.png")} alt="loading..." />
                                <span>15927642818 苏工</span>
                            </div> */}
                        </div>
                        <div className='link'>
                            <span className='title'>友情链接</span>
                            <a className='project' href="https://www.vanke.com" target="_blank" rel="noopener noreferrer">万科集团</a>
                            <a className='project' href="https://www.vanyitech.com" target="_blank" rel="noopener noreferrer">万翼科技</a>
                            <a className='project' href="https://www.vanyitech.com/profession/aidesign" target="_blank" rel="noopener noreferrer">万翼AI审图</a>
                            <a className='project' href="https://www.dougongdesign.com/" target="_blank" rel="noopener noreferrer">万翼斗拱</a>
                        </div>
                        {/* <div className='attention'>
                            <span className='title'>关注我们</span>
                            <div className='vessel'>
                                <div className='qrcode'>
                                    <img src={require("../assets/images/qrcode1.png")} alt="loading..." />
                                    <span>翼排客服</span>
                                </div>
                                <div className='qrcode'>
                                    <img src={require("../assets/images/qrcode2.png")} alt="loading..." />
                                    <span>翼排客服</span>
                                </div>
                            </div>
                        </div> */}
                        <div className="code">
                            <div className="title">扫码关注</div>
                            <div className="code-list">
                                <div className="code-item">
                                    <img src={require('../assets/home/wechat_code.png')} alt="" className="img" />
                                    <div className="text">微信公众号</div>
                                </div>
                                <div className="code-item">
                                    <img src={require('../assets/home/dy_code.png')} alt="" className="img" />
                                    <div className="text">抖音号</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='record'>
                        <span>主办单位：万翼科技有限公司</span>
                        <a href="https://beian.miit.gov.cn" target="blank" style={{ marginLeft: "20px" }}>© 翼排  粤ICP备19013065号</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer