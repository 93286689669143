import React from 'react';
import { Tabs, Row, Col, Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import ReactPlayer from "react-player";
const { TabPane } = Tabs;

class Content extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      key: '1',
      v4: require('../assets/video/v4.mp4'),
      v5: require('../assets/video/v5.mp4'),
      v6: require('../assets/video/v6.mp4')
    }
  }

  callback = key => {
    this.setState({ key: key })
  }

  render() {
    const { key, v4, v5, v6 } = this.state;
    return (
      <div className='content1'>
        <OverPack className="home-layout" playScale={0.4}>
          <QueueAnim className="home-case" type="bottom" key="home-case" ease="easeOutQuart" leaveReverse>
            <div key="feasibiity" className='feasibiity'>在翼排，你可以...</div>
            <div key="solve" className='solve'>不同需求，一站式解决方案</div>
            <QueueAnim
              key="content"
              type="bottom"
              component={Row}
              componentProps={{ gutter: 171 }}
              className='que-main'
            >
              <Col key='1' className="col" span={10}>
                <div className='adv_operate_content'>
                  <Tabs onChange={this.callback} activeKey={key} className='adv_tabs'>
                    <TabPane
                      tab={
                        <div className='tab_pane'>
                          <img src={require("../assets/images/design.svg")} alt="loading..." />
                          <span>辅助设计</span>
                        </div>
                      }
                      key="1"
                    />
                    <TabPane
                      tab={
                        <div className='tab_pane'>
                          <img src={require("../assets/images/explore.svg")} alt="loading..." />
                          <span>多方案探索</span>
                        </div>
                      }
                      key="2"
                    />
                    <TabPane
                      tab={
                        <div className='tab_pane'>
                          <img src={require("../assets/images/through.svg")} alt="loading..." />
                          <span>数据打通</span>
                        </div>
                      }
                      key="3"
                    />
                  </Tabs>
                  <div className='container'>
                    {
                      Number(key) === 1 ? (<>
                        <div className='feedback'> 实时反馈，快速验证 </div>
                        <div className='brief' style={{ marginTop: '16px' }}> 生成前排布预览验证楼型选择 </div>
                        <div className='brief'> 在线编辑日照、规范检测一键反馈 </div>
                        <div className='brief'> 让调整有迹可循，设计不再“盲人摸象” </div>
                        <Button className='advance' onClick={() => this.props.openApplyModal()}>
                          <span>立刻体验</span>
                          <img src={require("../assets/images/enter.svg")} alt="loading..." />
                        </Button>
                      </>) : (Number(key) === 2 ? (<>
                        <div className='feedback'> 多维方案，一键对比 </div>
                        <div className='brief' style={{ marginTop: '16px' }}> 多策略输出，多种可能一次性展示 </div>
                        <div className='brief'> 多产品支持，板楼、点楼、洋房全覆盖 </div>
                        <div className='brief'> 助您充分挖掘场地潜力 </div>
                        <Button className='advance' onClick={() => this.props.openApplyModal()}>
                          <span>立刻体验</span>
                          <img src={require("../assets/images/enter.svg")} alt="loading..." />
                        </Button>
                      </>) : (<>
                        <div className='feedback'> 打通数据，研判更有据 </div>
                        <div className='brief' style={{ marginTop: '16px' }}> 生成前排布预览验证楼型选择 </div>
                        <div className='brief'> 方案指标同步生成 拉通强排方案的成本与营销数据* </div>
                        <div className='brief'> 与多环节商议有理有据 </div>
                        <div className='custom'>*成本、营销数据的计算支持定制，欢迎联系洽谈</div>
                        <Button className='advance' style={{ marginTop: '67px' }} onClick={this.props.openApplyModal}>
                          <span>合作洽谈</span>
                          <img src={require("../assets/images/enter.svg")} alt="loading..." />
                        </Button>
                      </>))
                    }
                  </div>
                </div>
              </Col>
              <Col key='2' className="col" span={14}>
                <div className='adv_operate_sample'>
                  <ReactPlayer
                    muted={true}
                    url={v4}
                    width="100%"
                    height="100%"
                    playing={true}
                    loop={true}
                    volume={0}
                    className='player'
                    style={{ display: Number(key) === 1 ? 'block' : 'none' }}
                  />
                  <ReactPlayer
                    muted={true}
                    url={v5}
                    width="100%"
                    height="100%"
                    playing={true}
                    loop={true}
                    volume={0}
                    className='player'
                    style={{ display: Number(key) === 2 ? 'block' : 'none' }}
                  />
                   <ReactPlayer
                    muted={true}
                    url={v6}
                    width="100%"
                    height="100%"
                    playing={true}
                    loop={true}
                    volume={0}
                    className='player'
                    style={{ display: Number(key) === 3 ? 'block' : 'none' }}
                  />
                </div>
              </Col>
            </QueueAnim>
          </QueueAnim>
        </OverPack>
      </div>
    );
  }
}

export default Content;
