import React from 'react';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

class Content extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      key: 0
    }
  }

  render() {
    const { key } = this.state;
    return (
      <div className='content2'>
        <OverPack className="home-layout" playScale={0.4}>
          <QueueAnim className="home-case" type="bottom" key="home-case" ease="easeOutQuart" leaveReverse>
            <div key="feasibiity" className='feasibiity'>拥有前沿的云计算技术生态</div>
            <div key="solve" className='solve'>扎实的云端技术，为方案生成提供硬核支撑</div>
            <QueueAnim
              key="content"
              type="bottom"
              component={Row}
              componentProps={{ gutter: 171 }}
              className='que-main'
            >
              <Col key='1' className="col" span={24}>
                <div className="container">
                  {/*  云端智能计算  */}
                  <div
                    className="vessel"
                    onMouseEnter={() => { this.setState({ key: 0 }) }}
                    onMouseLeave={() => { this.setState({ key: 0 }) }}
                    style={{ background: key === 0 ? '#5E8AFF' : '#FAFBFC' }}
                  >
                    {key === 0 ?
                      <>
                        <div className="view_h">
                          <img src={require("../assets/images/clouds_h.svg")} alt="loading..." />
                        </div>
                        <div className="title_h">云端智能计算</div>
                        <div className="brief_h">云资源处理器、性能优</div>
                      </> :
                      <>
                        <div className="title">云端智能计算</div>
                        <div className="brief">设计师、算法、功能</div>
                        <div className="view">
                          <img src={require("../assets/images/clouds.svg")} alt="loading..." />
                        </div>
                        <div className="kind">
                          <div className="nape">云计算</div>
                          <div className="nape">云处理器</div>
                          <div className="nape">性能更优</div>
                          <div className="nape">运算更快</div>
                          <div className="nape">解除设备</div>
                          <div className="nape">随时随地</div>
                        </div>
                      </>}
                  </div>
                  {
                    key === 0 && <div
                      className="vessel_h"
                      onMouseEnter={() => { this.setState({ key: 0 }) }}
                      onMouseLeave={() => { this.setState({ key: 0 }) }}
                    >
                      <div>
                        <div className="brief">独享云资源，云处理器速度更快，性能更优</div>
                        <div className="brief">突破设备限制，随时随地即可开始强排</div>
                        <div className="brief">目前10分钟即可完成一套方案</div>
                      </div>
                    </div>
                  }
                  {/*  设计师思维算法  */}
                  <div
                    className="vessel"
                    onMouseEnter={() => { this.setState({ key: 1 }) }}
                    onMouseLeave={() => { this.setState({ key: 0 }) }}
                    style={{ background: key === 1 ? '#5E8AFF' : '#FFFFFF' }}
                  >
                    {key === 1 ?
                      <>
                        <div className="view_h">
                          <img src={require("../assets/images/stylist_h.svg")} alt="loading..." />
                        </div>
                        <div className="title_h">设计师思维算法</div>
                        <div className="brief_h">设计师、算法、功能</div>
                      </> :
                      <>
                        <div className="title">设计师思维算法</div>
                        <div className="brief">设计师、算法、功能</div>
                        <div className="view">
                          <img src={require("../assets/images/stylist.svg")} alt="loading..." />
                        </div>
                        <div className="kind">
                          <div className="nape">地块价值</div>
                          <div className="nape">特殊角度</div>
                          <div className="nape">极限初排</div>
                          <div className="nape">楼型推荐</div>
                          <div className="nape">组合计算</div>
                          <div className="nape">楼栋排布</div>
                        </div>
                      </>}
                  </div>
                  {
                    key === 1 && <div
                      className="vessel_h"
                      onMouseEnter={() => { this.setState({ key: 1 }) }}
                      onMouseLeave={() => { this.setState({ key: 0 }) }}
                    >
                      <div>
                        <div className="brief">配置带有设计师强排思维的特殊算法，作用于产品功能上：</div>
                        <div className="brief">思维的特殊算法，作用于产品功能上： 设计师可以提前处设置地块价值、特殊角度以保证强排方案如你所愿</div>
                        <div className="brief">单产品极限初排、楼型推荐、楼栋组合计算、楼栋排布预览。让决策更简单</div>
                      </div>
                    </div>
                  }
                  {/*  智能建筑规范库  */}
                  <div
                    className="vessel"
                    onMouseEnter={() => { this.setState({ key: 2 }) }}
                    onMouseLeave={() => { this.setState({ key: 0 }) }}
                    style={{ background: key === 2 ? '#5E8AFF' : '#FAFBFC' }}
                  >
                    {key === 2 ?
                      <>
                        <div className="view_h">
                          <img src={require("../assets/images/standard_h.svg")} alt="loading..." />
                        </div>
                        <div className="title_h">智能建筑规范库</div>
                        <div className="brief_h">城市全覆盖、自动计算</div>
                      </> :
                      <>
                        <div className="title">智能建筑规范库</div>
                        <div className="brief">城市全覆盖、自动计算</div>
                        <div className="view">
                          <img src={require("../assets/images/standard.svg")} alt="loading..." />
                        </div>
                        <div className="kind">
                          <div className="nape">自动退线</div>
                          <div className="nape">多拼面宽</div>
                          <div className="nape">车位计算</div>
                          <div className="nape">间距日照</div>
                          <div className="nape">数值显示</div>
                          <div className="nape">在线编辑</div>
                        </div>
                      </>}
                  </div>
                  {
                    key === 2 && <div
                      className="vessel_h"
                      onMouseEnter={() => { this.setState({ key: 2 }) }}
                      onMouseLeave={() => { this.setState({ key: 0 }) }}
                    >
                      <div>
                        <div className="brief">好方案，就要有理有据，无需皓首穷经，规范触手可及：</div>
                        <div className="brief">1.间距、退让、日照、面宽、车位、配套全覆盖 2.退线自动生成，多拼根据面宽自动调整，车位要求自动计算 3.间距、日照自动计算，秒速可得</div>
                        <div className="brief">实际值与规范值，即时显示 让方案调整，更加容易</div>
                      </div>
                    </div>
                  }
                  {/*  GIS底层引擎  */}
                  <div
                    className="vessel"
                    onMouseEnter={() => { this.setState({ key: 3 }) }}
                    onMouseLeave={() => { this.setState({ key: 0 }) }}
                    style={{ background: key === 3 ? '#5E8AFF' : '#FFFFFF' }}
                  >
                    {key === 3 ?
                      <>
                        <div className="view_h">
                          <img src={require("../assets/images/gis_h.svg")} alt="loading..." />
                        </div>
                        <div className="title_h">GIS底层引擎</div>
                        <div className="brief_h">高性能、地理信息系统</div>
                      </> :
                      <>
                        <div className="title">GIS底层引擎</div>
                        <div className="brief">高性能、地理信息系统</div>
                        <div className="view">
                          <img src={require("../assets/images/gis.svg")} alt="loading..." />
                        </div>
                        <div className="kind">
                          <div className="nape">周边信息</div>
                          <div className="nape">二三维视角</div>
                          <div className="nape">方案展示</div>
                          <div className="nape">快照截图</div>
                          <div className="nape">一目可及</div>
                          <div className="nape">不卡顿</div>
                        </div>
                      </>}
                  </div>
                  {
                    key === 3 && <div
                      className="vessel_h"
                      onMouseEnter={() => { this.setState({ key: 3 }) }}
                      onMouseLeave={() => { this.setState({ key: 0 }) }}
                    >
                      <div>
                        <div className="brief">结合高性能GIS引擎，即：地理信息系统</div>
                        <div className="brief">Geographic Information System</div>
                        <div className="brief">让目标地块的周边信息一目可及 二三维视角管理楼型排布情况 方案720度无死角展示，随你所想</div>
                      </div>
                    </div>
                  }
                </div>
              </Col>
            </QueueAnim>
          </QueueAnim>
        </OverPack>
      </div >
    );
  }
}

export default Content;
