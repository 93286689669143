import React from 'react';
import { Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

class Content extends React.PureComponent {

  render() {
    return (
      <div className='content5'>
        <OverPack className="home-layout" playScale={0.4}>
          <QueueAnim className="home-case" type="bottom" key="home-case" ease="easeOutQuart" leaveReverse>
            <div key="feasibiity" className='feasibiity'>在翼排，你还可以定制企业级的投研服务，更专业、更安全</div>
            <div key="main" className='main'>
              <div className='doable'>
                <div className='vessel'>
                  <img src={require("../assets/images/check.svg")} alt="loading..." />
                  <span>支持个人与团队全量功能</span>
                </div>
                <div className='vessel'>
                  <img src={require("../assets/images/check.svg")} alt="loading..." />
                  <span>拉通成本、营销数据</span>
                </div>
                <div className='vessel'>
                  <img src={require("../assets/images/check.svg")} alt="loading..." />
                  <span>第三方数据托管，保障数据安全</span>
                </div>
                <div className='vessel'>
                  <img src={require("../assets/images/check.svg")} alt="loading..." />
                  <span>提供方案接口，链接企业内部系统</span>
                </div>
              </div>
            </div>
            <div key="cooperation" className="cooperation">
              <Button className="talk_btn" onClick={this.props.openApplyModal}>立即洽谈</Button>
            </div>
          </QueueAnim>
        </OverPack>
      </div>
    );
  }
}

export default Content;
