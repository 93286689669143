
//判断环境路径
export function checkEnvFn() {
  const host = window.location.host;
  switch (host) {
    // 正式环境
    case 'www.dgaiplan.com':
      return { env: 'prod', url: 'https://www.dgaiplan.com', dougongUrl: 'https://www.dougongdesign.com' }
    // fat测试环境
    case 'aiplan360-fat.vanke.com':
      return { env: 'fat', url: 'http://aiplan360-fat.vanke.com', dougongUrl: 'http://vk3d-fat.vanke.com' }
    // dev环境
    default:
      // dev和生产环境都被干掉了，只能用fat
      return { env: 'fat', url: 'http://aiplan360-fat.vanke.com', dougongUrl: 'http://vk3d-fat.vanke.com' }
  }
}

//清除cookie
const domainEnv = checkEnvFn().env === "prod" ? "www.dgaiplan.com" : ".vanke.com";
export function clearCookie(name) {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  document.cookie = `${name}='';domain=${domainEnv};expires=${exp.toGMTString()}`;
}

//  设置cookie
export function setCookie(cname, cvalue, exdays, domain = domainEnv) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname + "=" + cvalue + "; " + expires + ";domain=" + domain + ";path=/;";
}

//  获取cookie
export function getCookie(cname) {
  var arr,
    reg = new RegExp("(^| )" + cname + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return arr[2];
  else return null;
}

/**
* @description 校验手机号格式
* @param {string} phones
* @return
*/
export function validatePhone(phones) {
  const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  return myreg.test(phones)
}