import React, { Component } from 'react';
import { Form, Input, Button, Modal, Select, message } from 'antd';
import { get, post } from '../axios';
const { Option } = Select;

export class info extends Component {
    constructor(props) {
        super(props)
        this.state = {
            provinceList: [],//省份
            cityList: [],//省份对应的城市
        };
        this.formRef = React.createRef();
    }
    componentDidMount() {
        this.getCity();
        this.init();
    }

    componentDidUpdate() {

    }

    //初始化（清空）
    init = () => {
        try {
            if (this.formRef && this.formRef.current) {
                this.formRef.current.setFieldsValue({
                    userName: null,
                    companyName: null,
                    phone: null,
                    province: null,
                    city: null
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    getCity = (code) => {
        try {
            let that = this;
            get(`/user-server/user/region/getProOrCity${code ? '?province=' + code : ''}`, {}).then(res => {
                if (res && res.value) {
                    if (code) {
                        that.setState({ cityList: res.value })
                    } else {
                        that.setState({ provinceList: res.value })
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        } catch (error) {
            console.log(error)
        }
    }

    //获取city
    handleChange = value => {
        const { provinceList } = this.state;
        let result = provinceList.filter(item => item.name === value);
        this.formRef.current.setFieldsValue({ city: null })
        this.getCity(result[0].code);
    }

    //提交
    submit = () => {
        let that = this;
        const values = that.formRef.current.getFieldsValue();
        that.formRef.current.setFieldsValue({
            userName: values.userName ? values.userName.replace(/(^\s*)|(\s*$)/g, "") : undefined,
            companyName: values.companyName ? values.companyName.replace(/(^\s*)|(\s*$)/g, "") : undefined,
            phone: values.phone ? values.phone.replace(/(^\s*)|(\s*$)/g, "") : undefined,
        })
        that.formRef.current.validateFields().then((data) => {
            post(`/qp-server/userData/add`, data).then(res => {
                if (res && res.code === 200) {
                    message.success(res.value);
                    that.props.cancel();
                    that.init();
                } else {
                    message.success('网络异常！');
                }
            }).catch(error => {
                console.log(error);
            })
        })
    }

    render() {
        const { provinceList, cityList } = this.state;
        const { visible } = this.props;
        return (
            <div className='info'>
                <Modal
                    width={382}
                    maskClosable={false}
                    visible={visible}
                    onCancel={this.props.cancel}
                    footer={null}
                    className='info-modal'
                >
                    <div className='header-nape'>
                        <img src={require("../assets/images/logo.svg")} alt="" />
                        <span className='name'>翼排</span>
                    </div>
                    <div className='relation'>我们将尽快与您取得联系, 期待与您的合作</div>
                    <Form ref={this.formRef} size="middle">
                        <Form.Item name="userName" label="" rules={[{ required: true, message: '请输入姓名！' }]}>
                            <Input className="info-ipt" placeholder="请输入姓名" maxLength={50} autoComplete='off' />
                        </Form.Item>
                        <Form.Item name="companyName" label="" rules={[{ required: true, message: '请输入公司名称！' }]} >
                            <Input className="info-ipt" placeholder="请输入公司名称" maxLength={50} autoComplete='off' />
                        </Form.Item>
                        <Form.Item name="phone" label="" rules={[{ required: true, message: '请输入手机号！' }, { pattern: /^1[3456789]\d{9}$/, message: "手机号格式错误！" }]}>
                            <Input className='info-ipt' placeholder="请输入手机号" maxLength={18} autoComplete='off' />
                        </Form.Item>
                        <Form.Item>
                            <Input.Group compact>
                                <Form.Item name="province" label="" rules={[{ required: true, message: '请选择省！' }]} style={{ width: '140px' }}>
                                    <Select className='info-sel' style={{ width: '140px' }} placeholder="请选择省" onChange={this.handleChange}>
                                        {
                                            provinceList.length > 0 && provinceList.map((item) => (
                                                <Option value={item.name} key={item.id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item name="city" label="" rules={[{ required: true, message: '请选择市！' }]} style={{ width: '140px', marginLeft: '20px' }}>
                                    <Select className='info-sel' style={{ width: '140px' }} placeholder="请选择市">
                                        {
                                            cityList.length > 0 && cityList.map((item) => (
                                                <Option value={item.name} key={item.id}>{item.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Form>
                    <Button className='submit' onClick={this.submit}>提交资料</Button>
                    <div className='info-footer'>
                        <img src={require("../assets/images/qrcode3.png")} alt="" />
                        <div className='brief'>
                            <p>您也可以在工作日：</p>
                            <p>10:00-12:00；13:30-18:30 联系我们</p>
                            <p>电话咨询：18665936912</p>
                            <p>微信咨询：扫描左侧二维码添加好友</p>
                        </div>
                    </div>
                </Modal>
            </div >
        )
    }
}

export default info