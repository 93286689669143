import React from 'react';
import { checkEnvFn, getCookie } from '../utils';
import './less/antMotionStyle.less';

import { BackTop } from 'antd';
import Header from './Header';
import Content0 from './Content0';
import Content1 from './Content1';
import Content2 from './Content2';
import Content3 from './Content3';
import Content4 from './Content4';
import Content5 from './Content5';
import AccountApplyModal from '../components/common/accountApplyModal';
import Info from './Info';
import Footer from './Footer';
import { get } from '../axios';


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      visible: false,//modal是否显示
      applyVisible: false,
    };
  }

  componentDidMount() {
    let token = getCookie("aiqp-accesstoken");
    if (token) {
      this.validateToken(token);
    }
  }

  //检验token
  validateToken = (token) => {
    let that = this;
    get(`/user-server/token/validateToken?token=${token}`, {}).then(res => {
      if (res && res.code === 200 && res.value) {
        that.setState({ token });
      } else {
        that.setState({ token: null });
      }
    }).catch(error => {
      console.log(error);
    })
  }

  //前往登录或项目首页
  login = flag => {
    const { token } = this.state;
    let url = token ? checkEnvFn().url + '/v' : checkEnvFn().url + (flag ? '/login' : '/login/#/register');
    window.location.href = url;
  }

  open = () => {
    this.setState({ visible: true })
  }

  cancel = () => {
    this.setState({ visible: false })
  }

  /**
  * @description 打开免费申请窗口
  * @param {*} data
  * @return
  */
  openApplyModal = () => {
    this.setState({ applyVisible: true });
  }

  /**
  * @description 关闭免费申请窗口
  * @param {*} data
  * @return
  */
  closeApplyModal = () => {
    this.setState({ applyVisible: false });
  }


  render() {
    const { token, visible, applyVisible } = this.state;
    const children = [
      <Header
        id="Header_0"
        key="Header_0"
        token={token}
        login={this.login}
        openApplyModal={this.openApplyModal}
      />,
      <Content0
        id="Content0_0"
        key="Content0_0"
        login={this.login}
        openApplyModal={this.openApplyModal}
      />,
      <Content1
        id="Content0_1"
        key="Content0_1"
        open={this.open}
        login={this.login}
        openApplyModal={this.openApplyModal}
      />,
      <Content2
        id="Content2_1"
        key="Content2_1"
        openApplyModal={this.openApplyModal}
      />,
      <Content3
        id="Content3_1"
        key="Content3_1"
        openApplyModal={this.openApplyModal}
      />,
      <Content4
        id="Content4_1"
        key="Content4_1"
        open={this.open}
        login={this.login}
        openApplyModal={this.openApplyModal}
      />,
      <Content5
        id="Content5_1"
        key="Content5_1"
        open={this.open}
        openApplyModal={this.openApplyModal}
      />,
      <Info
        id="Info_1"
        key="Info_1"
        visible={visible}
        cancel={this.cancel}
        openApplyModal={this.openApplyModal}
      />,
      <Footer
        id="Footer_1"
        key="Footer_1"
        login={this.login}
        openApplyModal={this.openApplyModal}
      />
    ];
    return (
      <div className="templates-wrapper">
        {children}
        <div className="consult" onClick={this.open}>
          <img src={require("../assets/images/hint.svg")} alt="loading..." />
        </div>
        <BackTop className="scroll-top">
          <img src={require("../assets/images/back-top.svg")} alt="loading..." />
        </BackTop>
        {
          applyVisible && <AccountApplyModal closeApplyModal={this.closeApplyModal} ></AccountApplyModal>
        }
      </div>
    );
  }
}
