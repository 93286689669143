import React from 'react';
import { Row, Col, Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { checkEnvFn } from '../utils';
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";
import ReactPlayer from "react-player";
import screenfull from 'screenfull';

class Content extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      v1: require('../assets/video/v1.mp4'),
      v2: require('../assets/video/v2.mp4'),
      v3: require('../assets/video/v3.mp4'),
      visible: false
    };
    this.formRef = React.createRef();
  }


  componentDidMount() {
    setTimeout(() => {
      this.loadSwiper();
    }, 1000)

    //监听退出全屏
    screenfull.onchange(() => {
      if (!screenfull.isFullscreen) {
        this.setState({ visible: false })
      }
    })

  }

  //挂载swiper
  loadSwiper = () => {
    var swiper = new Swiper(".swiper-c0", {
      direction: getDirection(),
      speed: 500,
      autoplay: {
        delay: 15500,
        disableOnInteraction: false,//手动滑动不打断
      },
      loop: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
      on: {
        resize: function () {
          swiper.changeDirection(getDirection());
        }
      }
    });
    function getDirection() {
      var direction = window.innerWidth <= 760 ? "vertical" : "horizontal";
      return direction;
    }
  };

  //观看
  play = () => {
    let that = this;
    that.setState({ visible: true }, () => {
      if (that.formRef) {
        screenfull.request(that.formRef.current.getInternalPlayer());
      }
    })
  }

  render() {
    const { v1, v2, v3, visible } = this.state;
    return (
      <div className='content0'>
        <OverPack className="home-layout" playScale={0.4}>
          <QueueAnim className="home-case" type="bottom" key="home-case" ease="easeOutQuart" leaveReverse>
            <div key="feasibiity" className='feasibiity'>轻松做规划，高效好方案</div>
            <div key="solve" className='solve'>翼排致力于为地产建筑行业提供一站式智能设计解决方案。</div>
            <div key="live" className='solve'>让科技助力建筑设计师创造美好生活。</div>
            <div key="operate" className='operate'>
              <Button className='trial' onClick={() => this.props.openApplyModal()}>申请试用</Button>
              <Button className='watch' onClick={this.play}>
                <span>观看视频</span>
                <img src={require("../assets/images/play.svg")} alt="loading..." />
              </Button>
            </div>
            <QueueAnim
              key="content"
              type="bottom"
              component={Row}
              componentProps={{ gutter: 171 }}
              className='que-main'
            >
              <Col key='1' className="col" span={24}>
                <div className="swiper swiper-c0">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide slide-item">
                      <ReactPlayer
                        className='play'
                        muted={true}
                        url={v1}
                        width="1080px"
                        height="607px"
                        playing={true}
                        loop={true}
                        volume={0}
                      >
                      </ReactPlayer>
                      <div className='swiper-title'>
                        三步完成即可生成方案， 规范自动匹配，规划条件，设计偏好均可配置
                      </div>
                    </div>
                    <div className="swiper-slide slide-item">
                      <ReactPlayer
                        className='play'
                        muted={true}
                        url={v2}
                        width="1080px"
                        height="607px"
                        playing={true}
                        loop={true}
                        volume={0}
                      >
                      </ReactPlayer>
                      <div className='swiper-title'>
                        GIS引擎二三维动态展示，方案数据实时查看
                      </div>
                    </div>
                    <div className="swiper-slide slide-item">
                      <ReactPlayer
                        className='play'
                        muted={true}
                        url={v3}
                        width="1080px"
                        height="607px"
                        playing={true}
                        loop={true}
                        volume={0}
                      >
                      </ReactPlayer>
                      <div className='swiper-title'>
                        在线编辑直接修改，间距、日照、方案指标一键更新
                      </div>
                    </div>
                  </div>
                  <div className="swiper-button-prev swiper-btn sw-prev"></div>
                  <div className="swiper-button-next swiper-btn sw-next"></div>
                </div>
              </Col>
            </QueueAnim>
          </QueueAnim>
        </OverPack>
        {
          visible && <ReactPlayer
            ref={this.formRef}
            url={`${checkEnvFn().url}/qp-file/product-presentation.mp4`}
            width="100%"
            height="100%"
            playing={true}
            loop={true}
            volume={1}
            controls
          >
          </ReactPlayer>
        }
      </div>
    );
  }
}

export default Content;
