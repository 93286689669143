import axios from "axios";
import { checkEnvFn } from './utils';
axios.defaults.baseURL = checkEnvFn().url;

// axios.interceptors.request.use(
//   config => {
//     // config.headers["Access-Control-Allow-Origin"] = "*";
//     config.headers["Content-Type"] = "application/json";
//     return config;
//   },
//   err => {
//     return Promise.reject(err);
//   }
// );

// get请求
export function get(url,sendData){
    return new Promise((resolve,reject)=>{
        axios.get(url,{params:sendData}).then(res=>{
            resolve(res.data);    
      }).catch(error=>{
            reject(error);    
      })                 
    })
}

// post请求
export function post(url,sendData){
    return new Promise((resolve,reject)=>{
        axios.post(url,sendData).then(res=>{
            resolve(res.data);    
      }).catch(error=>{
            reject(error);    
      })                 
    })
}