import React from 'react';
import { Button } from 'antd';

class Header extends React.Component {
    render() {
        const { token } = this.props;
        return (
            <div className='header'>
                <div className='header-nape'>
                    <img src={require("../assets/images/logo.svg")} alt="" />
                    <span className='name'>翼排</span>
                    {/* <span className='help'>帮助中心</span>
                    <span className='regard'>关于我们</span> */}
                </div>
                <div className='header-operate'>
                    {
                        token ?
                            <>
                                <img src={require("../assets/images/portrait.png")} alt="" />
                                <Button className='trial' onClick={() => this.props.login()}>进入工作台</Button>
                            </> :
                            <>
                                <Button className='login' onClick={() => this.props.login(true)}>登录</Button>
                                <Button className='trial' onClick={() => this.props.openApplyModal()}>申请试用</Button>
                            </>
                    }
                </div>
            </div>
        );
    }
}

export default Header