import React from 'react';
import { Row, Col, Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

class Content extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      key: -1,
      feature: [
        'GIS引擎&云智能生成技术',
        '智能规范系统',
        '全国多城市规范库',
        '系统户型库',
        '企业楼型库',
        '项目共享与协同',
        '设计偏好定制'
      ]
    }
  }

  render() {
    const { feature, key } = this.state;

    return (
      <div className='content4'>
        <OverPack className="home-layout" playScale={0.4}>
          <QueueAnim className="home-case" type="bottom" key="home-case" ease="easeOutQuart" leaveReverse>
            <div key="feasibiity" className='feasibiity'>翼排为设计师、设计院提供工具服务</div>
            <div key="solve" className='solve'>不同群体的功能差异化适配，灵活挑选</div>
            <QueueAnim
              key="content"
              type="bottom"
              component={Row}
              componentProps={{ gutter: 171 }}
              className='que-main'
            >
              <Col key='1' className="col" span={12}>
                <div className='container'>
                  <div className='color-lump'></div>
                  <div className='role'>
                    <img src={require("../assets/images/person.svg")} alt="loading..." />
                    <span>个人体验版</span>
                  </div>
                  <div className='main'>
                    {
                      feature.map((item, index) => (
                        <div
                          key={index}
                          className={key === index ? 'vessel_h' : 'vessel'}
                          onMouseEnter={() => { this.setState({ key: index }) }}
                          onMouseLeave={() => { this.setState({ key: -1 }) }}
                        >
                          {/* {index > 3 && <div className='cover'></div>} */}
                          <span style={{ color: index > 3 ? '#BFBFBF' : '#595959' }}>{item}</span>
                          {
                            index > 3 ?
                              <img src={require("../assets/images/delete.svg")} alt="loading..." /> :
                              <img src={require("../assets/images/may.svg")} alt="loading..." />
                          }
                        </div>
                      ))
                    }
                  </div>
                  <div className='apply'>
                    <Button className='apply-btn' onClick={() => this.props.openApplyModal()}>申请试用</Button>
                  </div>
                </div>
              </Col>
              <Col key='2' className="col" span={12}>
                <div className='container'>
                  <div className='color-lump-t'></div>
                  <div className='role'>
                    <img src={require("../assets/images/team.svg")} alt="loading..." />
                    <span>团队协作版</span>
                  </div>
                  <div className='main'>
                    {
                      feature.map((item, index) => (
                        <div
                          key={index}
                          className={key === index ? 'vessel_h' : 'vessel'}
                          onMouseEnter={() => { this.setState({ key: index }) }}
                          onMouseLeave={() => { this.setState({ key: -1 }) }}
                        >
                          <span>{item}</span>
                          <img src={require("../assets/images/may.svg")} alt="loading..." />
                        </div>
                      ))
                    }
                  </div>
                  <div className='apply'>
                    <Button className='apply-btn' onClick={this.props.openApplyModal}>联系我们</Button>
                  </div>
                </div>
              </Col>
            </QueueAnim>
          </QueueAnim>
        </OverPack>
      </div>
    );
  }
}

export default Content;
