import React from 'react';
import { validatePhone } from "../../../utils";
import { Button, Modal, Form, Input, message, Spin, Row, Col, Select, Alert } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { post } from '../../../axios';
import { checkEnvFn } from '../../../utils';
import "./index.less";

/**
* components-common-accountApplyModal
* @param {*} data
* @return
*/
let interval = null;
export class AccountApplyModal extends React.Component {
    /**
    * visible：显示
    * @param {*} data
    * @return
    */
    constructor(props) {
        super(props)
        this.state = {
            timing: false, //短信登录--设定请求验证码按钮
            count: 60, //短信登录--定时器--初始时间
            isNewMobileValid: false, // 新手机号是否格式正确
            spinning: false, // 加载中
            PROVINCE_LIST: [], // 省列表
            provinceCode: null,
            provinceName: null,
            CITY_LIST: [], // 市列表
            cityCode: null,
            cityName: null,
            isRegistered: false, // 已注册
            errorTips: '', // 错误内容
        }
        this.formRef = React.createRef();
    }

    /**
    * 挂载
    * @param {*} data
    * @return
    */
    componentDidMount() {
        this.getCityList();
    }

    /**
    * @description 获取省市列表
    * @param {*} data
    * @return
    */
    getCityList = () => {
        post(`${checkEnvFn().dougongUrl}/api/housetype/city/getRegion`, {}).then(res => {
            if (res && res.code === 200) {
                if (res) {
                    this.setState({ PROVINCE_LIST: res.value });
                }
            } else {
                message.error('网络异常！');
            }
        }).catch(error => {
            console.log(error);
        })
    }

    /**
     * @description 点击获取验证码按钮
     * @param {Event} e
     * @return
     */
    getCode = () => {
        let mobile = this.formRef.current.getFieldValue('mobile');
        // 调用获取验证码接口
        // this.props.dispatch({
        //     type: "app/sendMessage",
        //     payload: { target: mobile, type: 'register' },
        // })
        post(`${checkEnvFn().dougongUrl}/api/message/sms/captcha/send/unauthorized`, { target: mobile, type: 'register' }).then(() => {
            message.success("验证码发送中");
            this.setState({
                timing: true,
                isInputCode: false
            });
            // 开启定时器读秒
            interval = window.setInterval(() => {
                this.setState(preState => {
                    if (preState.count <= 1) {
                        clearInterval(interval); // 重置秒数
                        return {
                            count: 60,
                            timing: false
                        };
                    }
                    return { count: preState.count - 1 };
                });
            }, 1000);
        })
    };

    /**
    * @description 关闭
    * @param {*} data
    * @return
    */
    onCancel = () => {
        this.props.closeApplyModal();
    }

    /**
    * @description 提交
    * @param {*} data
    * @return
    */
    onFinish = (values) => {
        this.setState({ spinning: true });
        let { provinceName, cityName } = this.state;
        let params = {
            ...values,
            provinceName,
            cityName
        };
        post(`${checkEnvFn().dougongUrl}/api/sso/user/homepageAddUser`, params).then(res => {
            // sensorTrack('submit_apply', params);
            if (!res) return;
            params["errorCode"] = res.code;
            if (res.code === 200) {
                // sensorTrack('submit_apply_success', params);
                message.success('已提交申请，等待管理员审核');
                this.onCancel();
            } else if (res.code === 50014) {
                // sensorTrack('submit_apply_failed', params)
                this.setState({ isRegistered: true, errorTips: '该用户已注册' });
            } else if (res.code === 50015) {
                // sensorTrack('submit_apply_failed', params)
                this.setState({ isRegistered: true, errorTips: '该用户已提交申请，请勿重复操作' });
            }
        }).finally(() => {
            this.setState({ spinning: false });
        })
    };

    /**
    * @description 输入手机号
    * @param {*} data
    * @return
    */
    onNewPhoneChange = (e) => {
        const val = e.target.value;
        // 校验手机号格式
        let isNewMobileValid = validatePhone(val);
        this.setState({ isNewMobileValid, isRegistered: false });
    }

    /**
    * @description 选择省
    * @param {*} data
    * @return
    */
    changeProvince = (value, option) => {
        if (value) {
            const { PROVINCE_LIST } = this.state;
            let seletedItem = PROVINCE_LIST.find(item => item.code === value);
            this.setState({
                CITY_LIST: seletedItem.cities,
                provinceCode: option.code,
                provinceName: option.name
            });
        }
    }

    /**
    * @description 选择城市
    * @param {*} data
    * @return
    */
    changeCity = (value, option) => {
        if (value) {
            this.setState({
                cityCode: option.code,
                cityName: option.name
            });
        }
    }

    /**
    * 渲染
    * @param {*} data
    * @return
    */
    render() {
        const {
            timing,
            count,
            isNewMobileValid,
            isRegistered,
            spinning,
            PROVINCE_LIST,
            CITY_LIST,
            errorTips,
        } = this.state;
        return (
            <Spin spinning={spinning}>
                <Modal
                    visible={true}
                    onCancel={this.onCancel}
                    centered
                    className='accountApplyModal'
                    destroyOnClose={true}
                    mask={true}
                    maskClosable={false}
                    width={420}
                    footer={null}
                >
                    <div className='modal-header'>
                        <div className='header-nape'>
                            <img src={require("../../../assets/images/logo.svg")} alt="" />
                            <span className='name'>翼排</span>
                        </div>
                        <div className='title'>想体验翼排</div>
                        <div className='text'>请先填写以下信息, 我们将在<span className='blue_text'>三个工作日内回复</span></div>
                        <div className='text'>期待与您的合作</div>
                    </div>
                    <Form
                        ref={this.formRef}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            name="userName"
                            rules={[
                                { required: true, message: '姓名不能为空' },
                                { max: 50, message: '不能超过50个字符', validateTrigger: 'onChange' },
                            ]}
                        >
                            <Input
                                placeholder="请输入姓名"
                                className='user_ipt'
                                onChange={this.onNewPhoneChange}
                            />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={10}>
                                <Form.Item
                                    name="provinceCode"
                                    rules={[
                                        { required: true, message: '请选择省' },
                                    ]}
                                >
                                    <Select
                                        placeholder="省"
                                        className='user_sel'
                                        style={{ height: '40px' }}
                                        onChange={this.changeProvince}
                                        fieldNames={{ label: 'name', value: 'code' }}
                                        options={PROVINCE_LIST}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <Form.Item
                                    name="cityCode"
                                    rules={[
                                        { required: true, message: '请选择市' },
                                    ]}
                                >
                                    <Select
                                        placeholder="市"
                                        className='user_sel'
                                        style={{ height: '40px' }}
                                        onChange={this.changeCity}
                                        fieldNames={{ label: 'name', value: 'code' }}
                                        options={CITY_LIST}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="companyName"
                            rules={[
                                { required: true, message: '企业不能为空' },
                                { max: 50, message: '不能超过50个字符', validateTrigger: 'onChange' },
                            ]}
                        >
                            <Input
                                placeholder="请输入企业名称"
                                className='user_ipt'
                                onChange={this.onNewPhoneChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="mobile"
                            rules={[
                                { required: true, message: '手机号不能为空' },
                                { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', validateTrigger: 'onChange' },
                            ]}
                        >
                            <Input
                                placeholder="请输入手机号"
                                className='user_ipt'
                                onChange={this.onNewPhoneChange}
                            />
                        </Form.Item>
                        <Form.Item className='codebtn_wrapper'>
                            <Form.Item
                                name="captcha"
                                noStyle
                                rules={[
                                    { required: true, message: '验证码不能为空' },
                                    { len: 6, message: '请输入6位验证码', validateTrigger: 'onChange' }
                                ]}
                            >
                                <Input placeholder="请输入6位验证码" className='code_ipt' />
                            </Form.Item>
                            <Button
                                disabled={timing || !isNewMobileValid}
                                onClick={this.getCode}
                                className='codebtn'
                                style={{ color: timing ? 'rgba(2, 6, 18, 0.4)' : '' }}
                            >
                                {timing ? `${count}s` : "获取验证码"}
                            </Button>
                        </Form.Item>
                        {
                            isRegistered && <Alert
                                message={errorTips}
                                type="error"
                                showIcon
                                icon={<CloseCircleOutlined style={{ color: '#FF4D4F' }} />}
                            />
                        }
                        <Form.Item className='submit_wrapper'>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className='submit'
                            >
                                提交申请
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        )
    }
}

export default AccountApplyModal;
